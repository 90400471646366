import { Space } from 'antd';
import React, { useCallback } from 'react';
import { FormDataConsumer, useTranslate } from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { IntegerInput, TextInput } from '../../../../../base/components/ra/inputs';
import GroupInput from './GroupInput';
import BrandInput from './BrandInput';
import InputWithTooltipWrapper from '../../../../../base/components/common/InputWithTooltipWrapper';
import ExpirationTimeInput from './ExpirationTimeInput';
import StockInput from './StockInput';
import IssuerInput from './IssuerInput';
import CurrencyInput from './CurrencyInput';
import FreeSpinExpireUnitInput from './FreeSpinExpireUnitInput';
import { CAMPAIGN_ISSUERS } from '../../../../../constant/campaign';
import { isSystemAdmin } from '../../../../../services/util/auth';

const BasicInfoStep = ({
  isEditForm, initialValue,
}) => {
  const t = useTranslate();
  const isSA = isSystemAdmin();
  const { initialValues } = useFormState();
  const isNotIssuer = isEditForm && initialValues.issuer === CAMPAIGN_ISSUERS.NG && !isSA;

  const isUneditableField = useCallback(field => !!(initialValue?.uneditableFields || []).find(uneditableField => uneditableField === field), [initialValue?.uneditableFields]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: 28,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          gap: 12,
        }}
      >
        <Typography variant="h3">{t('resources.campaign.basic-info')}</Typography>
        <Box>
          <Box
            component={Space}
            width="100%"
            size="middle"
          >
            <Box
              width={{
                md: 345,
              }}
            >
              <TextInput
                label="resources.campaign.fields.name"
                source="campaignName"
                disabled={isUneditableField('name') || isNotIssuer}
                required
              />
            </Box>

            <Box
              width={{
                md: 345,
              }}
            >
              <InputWithTooltipWrapper
                source="campaignCode"
                title={t('resources.campaign.fields.code')}
                tooltip={t('resources.campaign.fields.code-tooltip')}
                required
                disabled={isEditForm || isNotIssuer || isUneditableField('campaignCode')}
              >
                <TextInput />
              </InputWithTooltipWrapper>
            </Box>
          </Box>
          <Box
            component={Space}
            width="100%"
            size="middle"
          >
            <Box
              width={{
                md: 345,
              }}
            >
              <InputWithTooltipWrapper
                source="expDate"
                title={t('resources.campaign.fields.exp-date')}
                tooltip={t('resources.campaign.fields.exp-date-tooltip')}
                required
                isEditForm={isEditForm}
                disabled={isNotIssuer || [isUneditableField('startTime'), isUneditableField('endTime')]}
              >
                <ExpirationTimeInput />
              </InputWithTooltipWrapper>
            </Box>

            <Box
              width={{
                md: 345,
              }}
            >
              <IssuerInput
                disabled={isUneditableField('issuer') || isEditForm}
              />
            </Box>
          </Box>
          <Box
            component={Space}
            width="100%"
            size="middle"
          >
            <Box
              width={{
                md: 345,
              }}
            >
              <GroupInput
                source="groupId"
                disabled={isEditForm || isUneditableField('groupId')}
                isRequired
              />
            </Box>
            <Box
              width={{
                md: 345,
              }}
            >
              <FormDataConsumer>
                {({
                  formData, ...rest
                }) => (
                  <BrandInput
                    groupId={formData.groupId}
                    source="brandId"
                    disabled={isEditForm || isUneditableField('brandId')}
                    isRequired
                    {...rest}
                  />
                )}
              </FormDataConsumer>
            </Box>
          </Box>
          <TextInput
            label="resources.campaign.fields.desc"
            source="description"
            fullWidth
            multiline
            resettable
            disabled={isUneditableField('description') || isNotIssuer}
          />
        </Box>

      </Box>

      <Box
        display="flex"
        flexDirection="column"
        sx={{
          gap: 12,
        }}
      >
        <Typography variant="h3">{t('resources.campaign.free-spins-settings')}</Typography>
        <Box>
          <Box
            display="flex"
            sx={{
              gap: 24,
            }}
          >
            <StockInput
              source="stock"
              label="resources.campaign.fields.stock"
              disabled={isNotIssuer || isUneditableField('stock')}
              isEditForm={isEditForm}
            />
            <Box
              width={{
                md: 345,
              }}
              display="flex"
              sx={{
                gap: 8,
              }}
            >
              <InputWithTooltipWrapper
                source="freeSpinExpireIn"
                title={t('resources.campaign.fields.issued-date')}
                tooltip={t('resources.campaign.fields.issued-date-tooltip')}
                required
                disabled={isNotIssuer || isUneditableField('freeSpinExpireIn')}
              >
                <IntegerInput />
              </InputWithTooltipWrapper>
              <Box width={96}>
                <FreeSpinExpireUnitInput disabled={isNotIssuer || isUneditableField('freeSpinExpireUnit')} />
              </Box>
            </Box>
            <CurrencyInput
              source="currencyId"
              disabled={isEditForm}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicInfoStep;
