/* eslint-disable no-plusplus, consistent-return, no-shadow, no-use-before-define, no-param-reassign, no-restricted-properties, no-undef */
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNotify, useRefresh, useTranslate } from 'react-admin';
import { uniqueId } from 'lodash';
import ExcelJs from 'exceljs';
import { saveAs } from 'file-saver';
import { useDispatch } from 'react-redux';
import { sanitizeObject } from '../../../services/util';
import { CAMPAIGN_TYPES,
  CAMPAIGN_UNIT_EXPIRE_IN,
  MAXIMUM_ADDED_PLAYERS,
  MAX_NATIVE_ID_LENGTH,
  DEFAULT_MAX_TOTAL_FREE_SPINS_WHEN_INPUT,
  MAX_VALID_PERIOD_IN_MONTHS } from '../../../constant/campaign';
import { showConfirmDialog } from '../../../services/redux/app/app.actions';
import { formatNumber } from '../../../services/util/formatNumber';
import resourceSlug from '../../../constant/resource-slug';

export const validateCampaign = (values, t, settings) => {
  const errors = {};
  if (!values.campaignName) {
    errors.campaignName = 'ra.validation.required';
  } else if (values.campaignName.trim().length > 64) {
    errors.campaignName = {
      message: 'ra.validation.maxLength',
      args: {
        max: 64,
      },
    };
  }

  if (!values.campaignCode) {
    errors.campaignCode = 'ra.validation.required';
  } else if (values.campaignCode.trim().length > 32) {
    errors.campaignCode = {
      message: 'ra.validation.maxLength',
      args: {
        max: 32,
      },
    };
  }

  if (!values.issuer) {
    errors.issuer = 'ra.validation.required';
  }

  if (!values.groupId) {
    errors.groupId = 'ra.validation.required';
  }

  if (!values.brandId) {
    errors.brandId = 'ra.validation.required';
  }

  if (!values.expDate?.[1]) {
    errors.expDate = 'ra.validation.required';
  } else if (moment(values.expDate[1]).diff(values.expDate?.[0] ? moment(values.expDate[0]) : moment(), 'years', true) > 1) {
    errors.expData = 'resources.campaign.validation.exp-date-max-a-year';
  }

  if (!values.freeSpinExpireIn) {
    errors.freeSpinExpireIn = 'ra.validation.required';
  } else if (values.freeSpinExpireIn > 30 * MAX_VALID_PERIOD_IN_MONTHS && values.freeSpinExpireUnit === CAMPAIGN_UNIT_EXPIRE_IN.DAYS) {
    errors.freeSpinExpireIn = t('resources.campaign.validation.max-value', {
      max: formatNumber('en', 30 * MAX_VALID_PERIOD_IN_MONTHS),
    });
  } else if (values.freeSpinExpireIn > 30 * MAX_VALID_PERIOD_IN_MONTHS * 24 && values.freeSpinExpireUnit === CAMPAIGN_UNIT_EXPIRE_IN.HOURS) {
    errors.freeSpinExpireIn = t('resources.campaign.validation.max-value', {
      max: formatNumber('en', 30 * MAX_VALID_PERIOD_IN_MONTHS * 24),
    });
  }

  if (values.description && values.description.trim().length > 255) {
    errors.description = {
      message: 'ra.validation.maxLength',
      args: {
        max: 255,
      },
    };
  }

  if (!values.currencyId) {
    errors.currencyId = 'ra.validation.required';
  }

  const maxStockInput = settings?.campaign_free_spin_quantity_max || DEFAULT_MAX_TOTAL_FREE_SPINS_WHEN_INPUT;

  if (values.stock === 0) {
    errors.stock = t('resources.campaign.validation.min-value', {
      min: 1,
    });
  } else if (values.stock && values.stock > maxStockInput) {
    errors.stock = t('resources.campaign.validation.max-value', {
      max: formatNumber('en', maxStockInput),
    });
  }

  return errors;
};

export const formatCampaignData = (values, isEdit) => (sanitizeObject({
  campaign: {
    name: values.campaignName.trim(),
    campaignCode: values.campaignCode.trim(),
    campaignType: values.campaignType || CAMPAIGN_TYPES.MARKETING_FREE_SPIN,
    issuer: values.issuer,
    brandId: values.brandId,
    description: values.description?.trim(),
    stock: values.stock ?? null,
    freeSpinExpireIn: values.freeSpinExpireIn,
    freeSpinExpireUnit: values.freeSpinExpireUnit,
    startTime: !values.expDate?.[0] || (!isEdit && moment(values.expDate[0]).isSameOrBefore(moment())) ? null : moment(values.expDate[0]).toISOString(),
    endTime: moment(values.expDate[1]).toISOString(),
    currencyId: values.currencyId,
  },
}));

export const parseCampaignData = values => ({
  id: values.id,
  campaignName: values.name,
  campaignCode: values.campaignCode,
  expDate: [values.startTime ? moment(values.startTime) : undefined, moment(values.endTime)],
  brandId: values.brandId,
  groupId: values.groupId,
  issuer: values.issuer,
  description: values.description,
  freeSpinExpireIn: values.freeSpinExpireIn,
  freeSpinExpireUnit: values.freeSpinExpireUnit,
  stock: values.stock,
  currencyId: values.currencyId,
  uneditableFields: values.uneditableFields || [],
});

export const parseClonedCampaignData = values => ({
  campaignName: values.name,
  expDate: [
    values.startTime && moment(values.startTime).startOf('hour').isAfter(moment().startOf('hour')) ? moment(values.startTime) : undefined,
    values.endTime && moment(values.endTime).startOf('hour').isAfter(moment().startOf('hour')) ? moment(values.endTime) : undefined,
  ],
  brandId: values.brandId,
  groupId: values.groupId,
  issuer: values.issuer,
  description: values.description,
  freeSpinExpireIn: values.freeSpinExpireIn,
  freeSpinExpireUnit: values.freeSpinExpireUnit,
  stock: values.stock,
  currencyId: values.currencyId,
});

export const useTableStyles = makeStyles(theme => ({
  betAmountSelector: {
    width: 120,
  },
  bold: {
    fontWeight: 600,
  },
  table: {
    "& [class*='ant-table-filter-trigger']:hover": {
      background: 'transparent',
    },
    '& .ant-table-thead .ant-table-cell': {
      color: theme.palette.grey[600],
    },
  },
  closeButton: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
}));

export const useTableListStyles = makeStyles(theme => ({
  root: {
    "& [class*='Paper']": {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
    },
  },
  wrapper: {
    margin: '16px 28px',
  },
  removeButton: {
    '&,&:hover': {
      color: theme.palette.error.main,
      boxShadow: 'none',
    },
  },
}));

export const useRemoveCampaignPlayer = campaignId => {
  const [isRemoving, setIsRemoving] = useState(false);
  const notify = useNotify();
  const t = useTranslate();

  const removePlayer = async (playerId, playerBatchId, options = {}) => {
    const {
      onSuccess, onError,
    } = options;
    setIsRemoving(true);

    try {
      await axios.post(`/api/${resourceSlug.MKT_FREE_SPINS}/withdraw-free-spins`, sanitizeObject({
        playerIds: playerId ? [playerId] : null,
        campaignId,
        playerBatchId,
      }));
      await onSuccess?.();
      notify(
        t('resources.campaign.removed-player-successfully'),
        'success',
      );
    } catch (error) {
      console.log(error);
      onError?.();
    } finally {
      setIsRemoving(false);
    }
  };

  return {
    removePlayer,
    isRemoving,
  };
};

export const useAddCampaignPlayers = campaignId => {
  const [isAdding, setIsAdding] = useState(false);
  const notify = useNotify();
  const t = useTranslate();
  const { getValue } = useSelectedAvailableGameValue();

  const addPlayers = async (data, options = {}) => {
    const {
      onSuccess, onError,
    } = options;
    setIsAdding(true);

    try {
      const issuedDate = data?.issuedDate ? moment(data.issuedDate).toISOString() : null;
      const players = data?.players?.reduce?.((accList, currentPlayer) => {
        accList[currentPlayer.nativeId] = currentPlayer.freeSpins || data?.freeSpinsPerPlayer;
        return accList;
      }, {});
      const gameConfigs = data?.games?.map(game => ({
        gameId: getValue(game, 'id'),
        baseBet: getValue(game, 'baseBet'),
      }));

      await axios.post(`/api/${resourceSlug.MKT_FREE_SPINS}/add-players`, {
        campaignId,
        players,
        issuedDate,
        gameConfigs,
        betAmount: data?.betAmount,
        freeSpinExpireIn: data?.freeSpinExpireIn,
        freeSpinExpireUnit: data?.freeSpinExpireUnit,
      });
      onSuccess?.();
      notify(
        t('resources.campaign.added-player-successfully'),
        'success',
      );
    } catch (error) {
      console.log(error);
      onError?.();
    } finally {
      setIsAdding(false);
    }
  };

  return {
    addPlayers,
    isAdding,
  };
};

export const INVALID_PLAYER_FILE_CODE = {
  EXCEED_TOTAL_ALLOWED_PLAYERS: 1,
  CONTAIN_INVALID_PLAYERS: 2,
  OTHER: 3,
};
class InvalidPlayerFileError extends Error {
  constructor(message, code, data) {
    super(message);
    this.data = data;
    this.code = code || INVALID_PLAYER_FILE_CODE.OTHER;
    this.name = 'InvalidPlayerFileError';
  }
}

const validateAndReadXlsxFiles = async file => {
  /* Data is an ArrayBuffer */
  const ab = await file.arrayBuffer();

  /* Parse */
  const workbook = new ExcelJs.Workbook();
  await workbook.xlsx.load(ab);

  /* Generate array of id from first worksheet */
  const ws = workbook.worksheets[0]; // Get the first worksheet

  const column = ws.getColumn(1);
  let totalInvalidPlayers = 0;
  const data = [];

  column.eachCell((cell, index) => {
    const nativeId = cell.value.toString().trim();
    // To remove header
    if (index === 1 || !nativeId) return;
    cell.alignment = {
      wrapText: true,
    };
    ws.getRow(index).height = 15;
    if (nativeId.length > MAX_NATIVE_ID_LENGTH) {
      totalInvalidPlayers += 1;
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'F69096',
        },
      };
    } else {
      data.push({
        id: uniqueId(),
        nativeId,
        freeSpins: undefined,
      });
    }
  });

  if (totalInvalidPlayers) {
    throw new InvalidPlayerFileError('File contains invalid players', INVALID_PLAYER_FILE_CODE.CONTAIN_INVALID_PLAYERS, {
      totalInvalidPlayers,
      wb: workbook,
    });
  } else if (data.length > MAXIMUM_ADDED_PLAYERS) {
    throw new InvalidPlayerFileError('File exceeds maximum allowed players', INVALID_PLAYER_FILE_CODE.EXCEED_TOTAL_ALLOWED_PLAYERS, {
      totalPlayers: data.length,
    });
  }

  return data;
};

export const convertXlsxFilesToPlayerList = async file => {
  const data = await validateAndReadXlsxFiles(file);

  return data;
};

export const downloadInvalidFile = workbook => {
  workbook.xlsx.writeBuffer().then(data => {
    const fileData = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(fileData, 'invalid_players.xlsx');
  });
};

export const formatBytes = (bytes, decimals = 1) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const useAvailableGames = (brandId, currencyId) => {
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!brandId || !currencyId) return;
    setIsLoading(true);

    axios.get(`/api/${resourceSlug.MKT_FREE_SPINS}/get-available-games?brandId=${brandId}&currencyId=${currencyId}`).then(response => {
      setGames(response?.data?.data);
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [brandId, currencyId]);

  return {
    games,
    isLoading,
  };
};

export const useSelectedAvailableGameValue = () => ({
  getValue: (value, key) => {
    const [id, baseBet] = value?.split('_') || [];
    if (!key) return [id, baseBet];
    if (key === 'id') return id;
    if (key === 'baseBet') return parseInt(baseBet, 10);
  },
  setValue: game => `${game.gameId}_${game.baseBet}`,
});

export const useCancelCampaign = campaignId => {
  const [isCanceling, setIsCanceling] = useState(false);
  const notify = useNotify();
  const t = useTranslate();
  const refresh = useRefresh();
  const dispatch = useDispatch();

  const confirm = options => {
    dispatch(
      showConfirmDialog({
        isOpen: true,
        title: 'resources.campaign.cancel-campaign-title',
        content: 'resources.campaign.cancel-campaign-desc',
        onConfirm: () => cancelCampaign(options),
      }),
    );
  };

  const cancelCampaign = async (options = {}) => {
    const {
      onSuccess, onError,
    } = options;
    setIsCanceling(true);

    try {
      await axios.post(`/api/${resourceSlug.MKT_FREE_SPINS}/cancel`, {
        campaignId,
      });
      onSuccess?.();
      notify(
        t('resources.campaign.canceled-campaign-successfully'),
        'success',
      );
      refresh();
    } catch (error) {
      console.log(error);
      onError?.();
    } finally {
      setIsCanceling(false);
    }
  };

  return {
    cancelCampaign: confirm,
    isCanceling,
  };
};
