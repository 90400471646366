import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { ACTION_MODULE, ACTION_TYPE } from '../../../../constant/campaign';
import CustomizedFilterWrapper from '../../../../base/components/customized/CustomizedFilterWrapper';
import DateRangePickerInput from '../../../../base/components/guesser/date-range-input';
import { TextInput } from '../../../../base/components/ra/inputs';
import resourceSlug from '../../../../constant/resource-slug';

const ChangeLogFilterWrapper = () => {
  const t = useTranslate();
  const { id } = useParams();

  return (
    <CustomizedFilterWrapper>
      <DateRangeInput
        source="created"
        label={t('resources.campaign.fields.created')}
        resource={`${resourceSlug.ACTION_LOG}/${id}/${resourceSlug.MKT_FREE_SPINS}`}
      />
      <SelectInput
        label="resources.campaign.fields.module"
        source="module"
        resettable
        choices={Object.values(ACTION_MODULE).map(module => ({
          id: module,
          name: t(`resources.campaign.module.${module}`),
        }))}
        variant="outlined"
        options={{
          SelectProps: {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          },
        }}
      />
      <SelectInput
        label="resources.campaign.fields.action"
        source="actionType"
        choices={Object.values(ACTION_TYPE).map(action => ({
          id: action,
          name: action,
        }))}
        resettable
        variant="outlined"
        options={{
          SelectProps: {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          },
        }}
      />
      <TextInput
        source="actor||$cont"
        label="resources.campaign.fields.actor"
        resettable
      />
    </CustomizedFilterWrapper>
  );
};

const DateRangeInput = props => (
  <DateRangePickerInput
    prefix
    ranges={[]}
    {...props}
  />
);

export default ChangeLogFilterWrapper;
