import React from 'react';
import { Tabs, Tab as MuiTab, Box, makeStyles } from '@material-ui/core';
import { useRedirect, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import resourceSlug from '../../../constant/resource-slug';

const useStyles = makeStyles(theme => ({
  flexContainer: {
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
  },
  tabRoot: {
    "&[class*='selected'] $tabWrapper": {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      fontWeight: 700,
    },
    padding: 0,
  },
  tabWrapper: {
    textTransform: 'capitalize',
    color: 'black',
    fontWeight: 600,
    padding: '10px 32px',
  },
}));

const Tab = props => {
  const classes = useStyles();

  return (
    <MuiTab
      classes={{
        root: classes.tabRoot,
        wrapper: classes.tabWrapper,
      }}
      {...props}
    />
  );
};

export const CampaignTab = props => {
  const {
    children, value, index, ...other
  } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const CAMPAIGN_TABS = {
  GENERAL_INFO: 0,
  PLAYER_FREE_SPINS: 1,
  CHANGE_LOG: 2,
};

const CampaignDetailsTabs = ({
  currentTab, children,
}) => {
  const t = useTranslate();
  const classes = useStyles();
  const redirect = useRedirect();
  const { id } = useParams();

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={currentTab}
          classes={{
            flexContainer: classes.flexContainer,
          }}
        >
          <Tab
            label={t('resources.campaign.general-info')}
            onClick={() => redirect(`/${resourceSlug.MKT_FREE_SPINS}/${id}/show`)}
            {...a11yProps(CAMPAIGN_TABS.GENERAL_INFO)}
          />
          <Tab
            label={t('resources.campaign.players-free-spins')}
            onClick={() => redirect(`/${resourceSlug.MKT_FREE_SPINS}/${id}/players`)}
            {...a11yProps(CAMPAIGN_TABS.PLAYER_FREE_SPINS)}
          />
          <Tab
            label={t('resources.campaign.change-log')}
            onClick={() => redirect(`/${resourceSlug.ACTION_LOG}/${id}/${resourceSlug.MKT_FREE_SPINS}`)}
            {...a11yProps(CAMPAIGN_TABS.CHANGE_LOG)}
          />
        </Tabs>
      </Box>
      {children}
    </Box>
  );
};

export default CampaignDetailsTabs;
