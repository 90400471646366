/* eslint-disable no-shadow */
import React, { useMemo } from 'react';
import { usePermissions, useShowController, useTranslate } from 'react-admin';
import WealthShowGuesser from '../../base/components/guesser/wealth-show.guesser';
import { checkCheatModeEnv } from '../../services/util';
import { useAuthUser } from '../../base/hooks';
import { WalletImplementationEnum, isUserSA } from '../../constant';
import { ActionMenu } from './components/action.menu';
import { UNLIMITED_PLAYER_VALUE } from './components/player-limit';
import resourceSlug from '../../constant/resource-slug';
import FeaturesTable from './components/features.table';
import CurrenciesTable from './components/currencies.table';
import SecretKey from './components/secret-key';
import Labeled from '../../base/components/ra/labeled';

export default function BrandShow(props) {
  const user = useAuthUser();
  const isSystemAdmin = isUserSA(user);
  const { record } = useShowController(props);
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const hasRegulationReadPermission = user?.superman
    || (Array.isArray(permissions) && !!permissions?.find(item => item.resource.name === resourceSlug.REGULATION)?.read);

  const hasFeaturesReadPermission = user?.superman
    || (Array.isArray(permissions) && !!permissions?.find(item => item.resource.name === resourceSlug.FEATURES)?.read);

  const isCheatMode = checkCheatModeEnv();

  const excludedKeys = useMemo(() => {
    // Always exclude features and currencies from auto-render to customize
    const keys = ['features', 'currencies', 'secretKey'];

    if (!isCheatMode) {
      keys.push('playerLimit');
    } else if (record?.playerLimit === UNLIMITED_PLAYER_VALUE) {
      record.playerLimit = translate('resources.brand.text.unlimited');
    }

    if (record?.walletIntegration?.implementation === WalletImplementationEnum.SEAMLESS) {
      keys.push('secretKey');
    } else if (record?.walletIntegration?.implementation === WalletImplementationEnum.TRANSFER) {
      keys.push('publicKey');
    }

    if (!hasRegulationReadPermission) {
      keys.push('regulation');
    }

    return keys;
  }, [record?.walletIntegration?.implementation, record?.playerLimit, hasRegulationReadPermission]);

  return (
    <WealthShowGuesser
      {...props}
      excludeFields={excludedKeys}
      sections={[
        hasFeaturesReadPermission && (
          <FeaturesTable
            title={translate('resources.feature-flag.name')}
            source="features"
          />
        ),
        <CurrenciesTable source="currencies" />,
      ]}
      actionMenu={isSystemAdmin ? (
        <ActionMenu
          type="toolbar"
          isSystemAdmin
        />
      ) : null}
      extraInformBlocks={[
        <Labeled
          source="secretKey"
          label="resources.brand.fields.secretKey"
        >
          <SecretKey {...props} />
        </Labeled>,
      ]}
    />
  );
}
