/* eslint-disable import/no-cycle, no-shadow */
import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation, useParams, Route } from 'react-router-dom';
import { SelectField, TextField, useLocale, useTranslate } from 'react-admin';
import { Card } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import { NavigationContext } from '../../../../App';
import CustomizedList from '../../../../base/components/customized/CustomizedList';
import CampaignDetailsTabs, { CAMPAIGN_TABS, CampaignTab } from '../CampaignDetailsTabs';
import { UUID_REGEX } from '../../../../constant';
import resourceSlug from '../../../../constant/resource-slug';
import { CUSTOM_ROUTES } from '../../../../constant/customRoutes';
import { DateField } from '../../../../base/components/ra/fields';
import { ACTION_MODULE } from '../../../../constant/campaign';
import ChangeLogAction from './ChangeLogActions';
import ChangeLogFilterWrapper from './ChangeLogFilters';
import { useTableListStyles } from '../utils';
import CommonWrapper from '../../../../base/components/reverse-integration/common-wrapper';
import ChangeLogDetailDrawer from './ChangeLogDetailDrawer';
import ChangeLogPagination from './ChangeLogPagination';

const ChangeLogList = props => {
  const t = useTranslate();
  const locale = useLocale();
  const { id } = useParams();
  const classes = useTableListStyles();

  const { setNavigationData } = useContext(NavigationContext);
  const {
    pathname, search,
  } = useLocation();

  const {
    actionLogList, currentActionLogPage,
  } = useSelector(({ campaign }) => campaign);

  const actions = useMemo(() => actionLogList[currentActionLogPage]?.data || [], [actionLogList, currentActionLogPage]);

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.campaign.details-title'),
        showSubLabel: false,
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.campaign.name'),
          link: `/${resourceSlug.MKT_FREE_SPINS}`,
        },
        {
          label: `#${pathname.split('/').find(path => path.match(UUID_REGEX)).slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, [t, pathname, locale]);

  return (
    <>
      <Card>
        <CampaignDetailsTabs currentTab={CAMPAIGN_TABS.CHANGE_LOG}>
          <CampaignTab
            value={CAMPAIGN_TABS.CHANGE_LOG}
            index={CAMPAIGN_TABS.CHANGE_LOG}
          >
            <CommonWrapper className={classes.wrapper}>
              <CustomizedList
                {...props}
                actions={<ChangeLogAction />}
                filters={<ChangeLogFilterWrapper />}
                basePath={CUSTOM_ROUTES.CAMPAIGN_CHANGES_LOG}
                hasList
                hasCreate={false}
                hasEdit={false}
                hasShow={false}
                exporter={false}
                resource={`${resourceSlug.ACTION_LOG}/${id}/${resourceSlug.MKT_FREE_SPINS}`}
                className={classes.root}
                syncWithLocation
                rowClick={actionId => `/${resourceSlug.ACTION_LOG}/${id}/${resourceSlug.MKT_FREE_SPINS}/${actionId}/show${search}`}
                pagination={<ChangeLogPagination />}
                controllerProps={{
                  data: keyBy(actions, 'id'),
                  ids: actions?.map(({ id }) => id),
                }}
              >
                <DateField
                  source="created"
                  label="resources.campaign.fields.created"
                />
                <SelectField
                  source="module"
                  label="resources.campaign.fields.module"
                  choices={Object.values(ACTION_MODULE).map(module => ({
                    id: module,
                    name: t(`resources.campaign.module.${module}`),
                  }))}
                  sortable={false}
                />
                <TextField
                  source="actor"
                  label="resources.campaign.fields.created-by"
                  sortable={false}
                />
                <TextField
                  source="actionType"
                  label="resources.campaign.fields.action"
                  sortable={false}
                />
              </CustomizedList>
            </CommonWrapper>

          </CampaignTab>
        </CampaignDetailsTabs>
      </Card>
      <Route
        exact
        path={`${CUSTOM_ROUTES.CAMPAIGN_CHANGES_LOG}/:actionId/show`}
        render={() => (
          <ChangeLogDetailDrawer />
        )}
      />
    </>

  );
};

export default ChangeLogList;
